*, * > * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    overflow-y: hidden;
}

body {
    background-image: url('../images/peixin_casual_lg.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
}

.navbar {
    background: rgba(0,0,0,0.9);
    box-shadow: 0px 2px 12px rgba(0,0,0,0.4);
}

.menu-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 2000;
    pointer-events: none;
    opacity: 0;
}

.lightbox-overlay {
    background: rgba(0,0,0,0.8);
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 4000;
    top: 0;
    left: 0;
    display: none;
}

.lightbox-image-container {
    max-width: 1200px;
    flex: 4;
    display: flex;
    align-items: center;
}

.lightbox-image-container > img {
    width: 100%;
    object-fit: cover;
    display: none;
}

.lightbox-image-container .close {
    position: absolute;
    top: 16px;
    right: 16px;
    height: 22px;
    width: 22px;
    cursor: pointer;
}

.lightbox-overlay .controls {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.lightbox-overlay .controls img {
    height: 64px;
    width: 64px;
    transition: .3s ease-out;
}

.lightbox-overlay .controls img:hover {
    transform: scale(1.25);
}

.overlay {
    background: #1A1A1A;
    height: 100vh;
    width: 100%;
    pointer-events: none;
    position: fixed;
    z-index: 1000;
    opacity: 1;
}

.container {
    width: 50%;
    max-width: 960px;
    margin: 0 auto;
    padding: 1.5rem 0;
}

.section-title {
    text-align: center;
    font-family: Oswald, Impact, Charcoal, sans-serif;
    font-size: 2rem;
    padding: 1rem 0 3rem;
    text-transform: uppercase;
}

.color-white {
    color: white;
}

.credits {
    font-family: Lora, "Palatino Linotype", serif;
    font-size: 1.125rem;
    padding: 20px 0 10px;
    text-align: center;
}

.cover {
    position: relative;
    z-index: 0;
    height: 100vh;
    background-image: url('../images/NusaKosar-14.jpg');    
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
}

.titles-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    width: 900px;
    text-align: center;
    perspective: 1000px;
    pointer-events: none;
}

.titles-container-mobile {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 780px;
    text-align: center;
    z-index: 500;
    transform-origin: 0% 0%;
    transform: scale(0.7, 0.7) translateX(-50%);
    display: none;
}

.titles-container-mobile h1 {
    color: rgba(255,255,255, 0.6);
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 25px;
    font-size: 8rem;
    font-family: Oswald, Impact, Charcoal, sans-serif;
}

.titles-container-mobile h2 {
    font-style: italic;
    font-family: Lora, "Palatino Linotype", serif;
    letter-spacing: 2px;
    transform: rotateZ(90deg) translate(-6px, 6px);
    font-size: 2rem;
    transform-origin: center;
    color: white;
    position: absolute;
    left: 57%;
    top: 43%;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding-bottom: 6px;
}

.titles-container h1 {
    font-family: Oswald, Impact, Charcoal, sans-serif;
    font-weight: 300;
    font-size: 8rem;
    text-transform: uppercase;
    color: white;
    visibility: hidden;
}

.titles-container h2 {
    font-style: italic;
    font-family: Lora, "Palatino Linotype", serif;
    letter-spacing: 2px;
    transform: rotateZ(90deg) rotateX(90deg);
    opacity: 0;
    transform-origin: center;
    color: white;
    position: absolute;
    left: 57%;
    top: 43%;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding-bottom: 6px;
}

.letter {
    opacity: 0;
}

nav {
    display: none;
    background: rgb(255,255,255);
    opacity: .4;
    height: 100vh;
    width: 23%;
    position: fixed;
    right: 0;
    top: 0;
    padding: 20px;
    padding-top: 60px;
    transform: translateX(100%);
    z-index: 3000;
}

nav .close {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

nav .close img {
    width: 100%;
}

nav .nav-title {
    text-align: center;
    border-top: 1px solid rgba(0,0,0,0.8);
    border-bottom: 1px solid rgba(0,0,0,0.8);
    color: rgba(0,0,0,0.8);
    text-transform: uppercase;
    font-size: 1rem;
    font-family: Oswald, Impact, Charcoal, sans-serif;
    padding: 10px;
    letter-spacing: 2px;
}

nav ul {
    list-style-type: none;
    font-family: Oswald, Impact, Charcoal, sans-serif;
    margin-top: 60px;
}

nav li {
    text-transform: uppercase;
    color: rgba(0,0,0,0.8);
    padding: 15px;
    text-align: center;
    font-size: 1.35rem;
    cursor: pointer;
}

.menu-toggler {
    cursor: pointer;
}

.menu-toggler img {
    float: right;
    margin-right: 10px;
}

.upcoming {
    position: absolute;
    width: 32rem;
    color: black;
    font-family: Lora, "Palatino Linotype", serif;
    background: rgba(132, 107, 67, 0.9);
    top: 55%;
    left: 5%;
    overflow: hidden;
}

.upcoming-header {
    padding: 1rem 1.4rem 0 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.upcoming-header h3 {
    font-family: Oswald, Impact, Charcoal, sans-serif;
    margin-bottom: .8rem;
    letter-spacing: -1px;
    font-size: 1.8rem;
    text-transform: uppercase;
    line-height: 1;
}

.upcoming-header h3 span {
    letter-spacing: 2px;
}

.upcoming-header .controls {
    display: flex;
}

.upcoming-header .controls img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.upcoming-header .controls img:first-child {
    margin-right: 30px;
    visibility: hidden;
}

.upcoming-slider {
    display: flex;
    position: relative;
    transition: .4s;
}

.upcoming-appointment {
    padding: 0 1.4rem 1rem 1.4rem;
    display: flex;
    width: 100%;
    align-items: center;
}

.upcoming-date p {
    font-size: 4.5rem;
    font-family: Cairo, Arial, sans-serif;
    letter-spacing: -2px;
    line-height: 1;
    color: white;
    padding-right: 20px;
    text-indent: -5px;
}

.upcoming-content {
    font-size: 1rem;
    flex: 1;
}

.upcoming-content .place {
    margin-top: 10px;
}

.biography {
    min-height: 100vh;
    background: #F3E9C6;
    box-shadow: 0px -2px 12px rgba(0,0,0,0.4);
    width: 100%;
}

.biography-content p {
    font-size: .95rem;
    font-family: Lora, "Palatino Linotype", serif;
    line-height: 1.75;
    margin-bottom: 2rem;
    text-align: justify;
}

.biography .biography-link {
    margin-bottom: 120px;
    text-align: center;
}

.biography-link a {
    font-size: 1.25rem;
    text-decoration: none;
    display: inline-block;
    padding-bottom: 2px;
    color: #486A47;
    border-bottom: 1px solid #486A47;
    transition: .3s;
    opacity: 1;
}

.biography-link a:hover {
    opacity: 0.6;
}

.gallery {
    background: #E85124;
    position: relative;
    z-index: 500;
    box-shadow: 0px -2px 12px rgba(0,0,0,0.4);
    padding-bottom: 20px;
    width: 100%;
}

.gallery .images {
    display: flex;
    justify-content: space-between;
}

.gallery .col-left, .gallery .col-right {
    width: 48%;
    display: flex;
    flex-direction: column;
}

.img-container {
    margin-bottom: 20px;
    box-shadow: 0 0 2px rgba(0,0,0,0.4), 0 0 12px rgba(0,0,0,0.2), 0 0 24px rgba(0,0,0,0.1);
    height: 180px;
    overflow: hidden;
    background: black;
    position: relative;
}

.img-container span {
    width: 100%;
    opacity: 0;
    color: white;
    font-size: 0.8rem;
    position: absolute;
    top: 80%;
    left: 0;
    text-align: center;
    pointer-events: none;
    transition: .3s;
    font-family: Lora, "Palatino Linotype", serif;
}

.img-container img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    cursor: pointer;
    transition: .3s ease-out;
}

.img-container img:hover {
    transform: scale(1.07);
    opacity: .4;
}

.img-container img:hover ~ span {
    opacity: 1;
}

.media {
    min-height: 100vh;
    box-shadow: 0px -2px 12px rgba(0,0,0,0.4), 0px 2px 12px rgba(0,0,0,0.4);
    z-index: 600;
    position: relative;
    background: #231b12;
    padding-bottom: 20px;
    width: 100%;
}

.media .audio {
    margin-bottom: 40px;
}

.media .audio .audio-label {
    color: white;
    margin-bottom: 8px;
    font-family: Lora, "Palatino Linotype", serif;
    font-size: 0.825rem;
    display: none;
}

.media iframe {
    box-shadow: 0 0 2px rgba(0,0,0,0.4), 0 0 12px rgba(0,0,0,0.2), 0 0 24px rgba(0,0,0,0.1);
}

.media .audio iframe {
    margin-bottom: 18px;
}

.media .video iframe {
    height: 400px;
    margin-bottom: 30px;
}

.contact {
    background: transparent;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
}

.contact-container {
    width: 65%;
    max-width: 960px;
    background: rgba(243, 235, 235, 0.95);
    padding: 30px;
    padding-top: 0;
    box-shadow: 0 0 67px rgba(0,0,0,0.2);
    border-radius: 16px;
}

.contact-inner-container {
    display: flex;
}

.contact-form {
    flex: 1;
    font-family: Lora, "Palatino Linotype", serif;
    padding-right: 30px;
}

.contact-form .field {
    margin: 18px 0;
}

.contact-form .field:first-of-type {
    margin-top: 0;
}

.contact-form label {
    margin-bottom: 6px;
    display: block;
}

.contact-form .form-input {
    display: block;
    padding: .6em 1em;
    border: 1px solid rgba(0,0,0,0.5);
    box-shadow: inset 0 0 2px rgba(0,0,0,0.6);
    width: 100%;
    font-size: 1.125rem;
    outline: none;
}

.contact-form .form-input:focus {
    border: 1px solid rgba(0,0,0,1);
}

.contact-form .submit-button {
    padding: .75rem 1.125rem;
    border: none;
    color: white;
    font-size: 1rem;
    background: rgba(0,0,0,0.6);
    font-family: Lora, "Palatino Linotype", serif;
    cursor: pointer;
}

.contact-form .submit-button:hover {
    background: rgba(0,0,0,0.8);
}

.contact-data {
    flex: 1;
    border-left: 1px solid rgba(0,0,0,0.5);
    padding-left: 30px;
    display: flex;
    flex-direction: column;
}

.contact-data ul {
    list-style-type: none;
    font-family: Lora, "Palatino Linotype", serif;
    font-size: 1.125rem;
    flex: 1;
}

.contact-data li {
    padding: 8px;
}

.contact-data i {
    margin-right: 8px;
}

.contact-data a i {
    margin-right: 0;
    margin-left: 4px;
}

.contact-data a {
    text-decoration: none;
}

.contact-data ul a {
    display: inline-block;
    padding-bottom: 4px;
    border-bottom: 1px solid transparent;
    transition: 0.2s;
    color: #486A47;
}

.contact-data ul a:hover {
    border-bottom: 1px solid #486A47;
}

.contact-data .social {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4px;
}

.social a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    width: 100%;
    transition: .3s;
    background: rgba(0,0,0,0.1);
    border-radius: 8px;
}

.social a img {
    height: 60px;
    width: 60px;
}

.social a:hover {
    background: rgba(0,0,0,0.2);
}

footer {
    background: #231B12;
}

footer p {
    color: white;
    font-family: Lora, "Palatino Linotype", serif;
    text-align: center;
    line-height: 3;
    font-size: .8rem;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .contact-data .social {
        display: -ms-flexbox;
    }

    .social a {
        flex: 1;
        margin-right: 6px;
        height: 70px;
    }

    .social a:last-of-type {
        margin: 0;
    }

    .social a img {
        height: 30px;
        width: 30px;
    }
}

@media (min-width: 1460px) {
    nav li {
        font-size: 1.6rem;
        padding: 20px;
    }

    .upcoming {
        width: 36rem;
    }

    .upcoming-header {
        padding: 1.4rem 2rem 0 2rem;
    }

    .upcoming-appointment {
        padding: 0 2rem 1.4rem 2rem;
    }

    .upcoming-header h3 {
        font-size: 2rem;
    }

    .upcoming-date p {
        font-size: 6rem;
        padding-right: 25px;
    }

    .upcoming-content {
        font-size: 1.2rem;
    }

    .biography-content p {
        font-size: 1.05rem;
    }

    .img-container, .img-container img {
        height: 215px;
    }

    .media .video iframe {
        height: 480px;
    }

    .contact .section-title {
        margin: 1rem 0;
    }

    .contact-data .social {
        grid-gap: 14px;
    }

    .contact-form .field {
        margin-bottom: 30px;
    }

    .contact-form .field:first-of-type {
        margin-top: 0;
    }
}

@media (min-width: 1640px) {
    .upcoming {
        width: 37.5rem;
    }

    .upcoming-date p {
        font-size: 6.5rem;
    }

    .upcoming-content {
        font-size: 1.3rem;
    }

    .img-container, .img-container img {
        height: 250px;
    }

    .media .video iframe {
        height: 520px;
    }
}

@media (max-width: 1100px) {
    nav {
        width: 30%;
    }

    .container {
        width: 55%;
    }

    .contact-container {
        width: 80%;
    }
}

@media (max-width: 899px) {
    html {
        overflow-y: scroll;
    }

    body, .cover {
        background-image: url('../images/NusaKosar-35.jpg');
        background-position: center;
    }

    header {
        z-index: 1500;
        background: rgba(0,0,0,0.9);
        box-shadow: 0px 2px 12px rgba(0,0,0,0.4);
    }
    
    .overlay, .titles-container {
        display: none;
    }

    .titles-container-mobile {
        display: block;
    }

    nav {
        display: block;
        width: 50%;
    }

    .container {
        width: 65%;
    }

    .biography .biography-link {
        margin-bottom: 40px;
    }

    .img-container span {
        opacity: 1;
        top: 87%;
        font-size: 0.725rem;
    }

    .upcoming {
        margin: 0 auto;
        top: 60%;
    }

    .contact {
        padding: 40px 0;
    }

    .contact-container {
        width: 90%;
    }

    .gallery {
        box-shadow: none;
    }

    .media {
        box-shadow: 0px 2px 12px rgba(0,0,0,0.4);
    }

    .media .audio .audio-label {
        display: block;
    }

    @media (max-width: 768px) {
        .section-title {
            font-size: 1.6rem;
        }

        .container {
            width: 80%;
        }

        .upcoming {
            width: 100%;
            left: 0;
            top: initial;
        }

        .upcoming-header h3 {
            font-size: 1.5rem;
        }

        .cover {
            min-height: 100vh;
            height: auto;
            display: flex;
            align-items: flex-end;
        }

        .media .video iframe {
            height: 320px;
        }

        .contact-container {
            padding: 0 20px 20px 20px;
        }

        .contact-data {
            padding-left: 20px;
        }

        .contact-data ul {
            font-size: 1rem
        }

        .contact-form .form-input {
            padding: .3rem .5rem;
        }

        .contact-data li {
            padding: 4px;
        }

        .contact-form {
            padding-right: 20px;
        }

        .social a {
            height: 106px;
        }
    }

    @media (max-width: 640px) {
        .titles-container-mobile {
            transform: scale(0.6, 0.6);
            transform-origin: -75% 0;
        }

        .lightbox-overlay .controls img {
            height: 40px;
            width: 40px;
        }

        .biography-link a {
            font-size: 1rem;
            padding-bottom: 0;
        }

        .img-container {
            height: 130px
        }

        .img-container img {
            height: 130px
        }

        .media .video iframe {
            height: 260px;
        }

        .contact-container {
            padding: 0 27px 27px 27px;
        }

        .contact-inner-container {
            flex-direction: column;
        }

        .contact-form {
            padding-right: 0;
            padding-bottom: 20px;
        }

        .contact-data {
            border: none;
            border-top: 1px solid rgba(0,0,0,0.5);
            padding-left: 0;
            padding-top: 20px;
        }

        .contact-data ul {
            margin-bottom: 20px;
        }

        .contact-data .social a {
            height: 90px;
        }

        .contact-data a img {
            height: 40px;
            width: 40px;
        }

        .contact-data .social {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 8px;
        }

        .contact-form .submit-button {
            width: 100%;
        }
    }

    @media (max-width: 480px) {
        .titles-container-mobile {
            transform: scale(0.5, 0.5);
            transform-origin: -50% 0;
            top: 17%;
            background: rgba(255,255,255, 0.1);
            box-shadow: 0 0 40px rgba(0,0,0.5);
        }

        .lightbox-overlay .controls img {
            height: 30px;
            width: 30px;
        }

        nav {
            width: 65%;
        }

        .upcoming-content {
            font-size: 0.925rem;
        }

        .upcoming-date p {
            font-size: 2.6rem;
        }

        .biography .biography-link {
            margin-bottom: 20px;
        }

        .biography-link a {
            border: 0;
            text-decoration: underline;
        }

        .img-container {
            height: 100px
        }

        .img-container img {
            height: 100px
        }

        .media .video iframe {
            height: 220px;
        }

        .contact-data .social a {
            height: 70px;
        }

        .contact-data a img {
            height: 30px;
            width: 30px;
        }
    }

    @media (max-width: 400px) {
        .titles-container-mobile {
            transform: scale(0.4, 0.4);
            transform-origin: -33.5% 0;
        }

        .titles-container-mobile h2 {
            font-size: 2rem;
        }

        .section-title {
            padding: .5rem 0 1.5rem;
        }

        nav {
            width: 80%;
        }

        nav ul {
            margin-top: 30px;
        }

        .upcoming-header {
            padding: 1rem .8rem 0 .8rem;
        }

        .upcoming-appointment {
            padding: 0 .8rem 1rem .8rem;
        }

        .upcoming-content {
            font-size: 0.875rem;
        }

        .upcoming-date p {
            font-size: 2rem;
        }

        .gallery .images {
            flex-direction: column;
        }

        .gallery .col-right, .gallery .col-left {
            width: 100%;
        }

        .img-container {
            height: 140px
        }

        .img-container img {
            height: 140px
        }

        .media .audio .audio-label {
            font-size: .75rem;
        }

        .media .video {
            padding-top: 20px;
        }

        .media .video iframe {
            height: 160px;
        }

        .media {
            box-shadow: none;
        }

        .contact {
            padding: 0;
        }

        .contact-container {
            width: 100%;
            border-radius: 0;
        }

        .contact-data .social a {
            height: 60px;
        }

        .contact-data a img {
            height: 20px;
            width: 20px;
        }

        .contact-data ul {
            font-size: .875rem
        }

        .contact-form .form-input {
            padding: .3rem .5rem;
        }

        .contact-data li {
            padding: 2px;
        }
    }

    @media (max-width: 319px) {
        .titles-container-mobile {
            transform: scale(0.32, 0.32);
            transform-origin: -24% 0;
        }
    }
}